export const imports = {
  'docs/Autocomplete.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-autocomplete" */ 'docs/Autocomplete.mdx'
    ),
  'docs/Dropdown.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-dropdown" */ 'docs/Dropdown.mdx'
    ),
  'docs/Home.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-home" */ 'docs/Home.mdx'
    ),
  'docs/MultiSelect.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-multi-select" */ 'docs/MultiSelect.mdx'
    ),
  'docs/Typeahead.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "docs-typeahead" */ 'docs/Typeahead.mdx'
    ),
}
